export default {
  username: "Username",
  delete: "Delete",
  "create-contest": "Create Contest",
  "join-contest": "Join Contest",
  notAdmin: "You must be an admin in the contest to access this page",
  notSuperAdmin:
    "You must be at least a super admin in the contest to view this content",
  forbidden: "You don't have access",
  "are-you-sure": "Are you sure?",
  showing: "Showing",
  "danger-zone": "Danger Zone",
  "deleting-contest-description":
    "Deleting the contest will delete all data related to it permanently",
  users: "Users",
  groups: "Groups",
  points: "Points",
  results: "Results",
  "action-cannot-be-undone": "This action cannot be undone",
  "delete-contest": "Delete Contest",
  "write-contest-code-to-confirm": "Write the contest code to confirm deletion",
  "write-contest-code": "Write {{code}} to confirm deletion",
  "contest-removed": "Contest has been removed",
};
