export default {
  participants: "Participants",
  "accepted-participants": "Accepted Participants ",
  delete: "Delete",
  reactive: "Reactive",
  addParticipantManually: "Add Participant Manually",
  deactivatedStudents: "Deactivated participants ",
  rejectedParticipants: "Rejected Participants",
  show: "Show",
  deactivate: "Deactivate",
  "set-admin": "Set as admin",
  "set-super-admin": "Set as super admin",
  "change-password": "Change password",
  "enter-new-password": "Enter new password",
  "confirm-new-password": "Confirm new password",
  "unmatched-passwords": "Passwords are not matched",
  "reset-password-failed-msg": "Password reset successfully",
  studentModify: "Student modification",
  photoUpdate: "Upload a personal photo",
  addStudent: "Add him as a student who can only watch",
  editStudent: "You must select a student to edit",
  successEdit: "The student has been modified successfully",
  notSuccessEdit: "The student has not been modified",
  mustSelect: "You must select a rider to change the password",
  changePassword: "The password has been changed successfully",
  notChangePassword: "The password has not been changed",
  enterPassword: "Enter a new password",
  addNO: "Enter a number",
  saveSuccess: "The changes have been saved successfully",
  notSaveSuccess: "The changes were not saved",
  noChange: "No change was made to save it",
  deleteSuccess: "Deleted successfully",
  notDelete: "Not deleted",
  selectStudentAndDay: "Select the day and student",
  noPoints: "There are no points for today",
  deleteResult: "Do you want to delete this result?",
  standardTitle: "Standard title",
  result: "The result",
  studentView: "Select a student to view statistics for each day",
  noStudentView: "There are no points to display statistics for each day",
  statisticsDay: "Statistics for every day",
  statisticsEach: "Select the student to view statistics for each standard",
  statisticsCriterion:
    "There are no points to display statistics for each criterion",
  statisticsEachStandard: "Statistics for each criterion",
  noStudentDiplay: "There are no students to display",
  clickView: "Click to view the table",
  viewChart: "Click to view the chart",
  chooseContestant: "Choose the contestant",
  chooseRamdan: "Choose the day of Ramadan",
  mainRole: "Role",
  role: {
    0: "Owner",
    1: "Super Admin",
    2: "Admin",
    3: "Member",
    4: "Read-Only Member",
    5: "Pending",
    6: "Deactivated",
  },
  notification: {
    success: "Student updated successfully",
    error: "Something went wrong, please try again later",
    addStudent: "Added successfully",
    errorStudent: "Please check the name",
  },
  approve: "Approve",
  reject: "Reject",

  "user-details": "User Details",
  email: "Email",
  all: "All",
  "add-user": "Add User",
  "change-role-to": "Change to",
  "show-results": "Results",
};
