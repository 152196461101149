export default {
  "add-moderator-manually": "Add Moderator Manually",
  go: "Go",
  deactivate: "Deactivate",
  moderators: "Moderators",
  search: "Search",
  "add-admin": "Add",
  "add-as-admin": "Add as admin",
  "add-as-super-admin": "Add as super admin",
};
