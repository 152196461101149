export default {
  logout: "تسجيل الخروج",
  emailNotVerified: "لم يتم تأكيد البريد الإلكتروني",
  emailNotVerifiedDescription:
    "تحتاج إلى تأكيد بريدك الإلكتروني <strong>{{email}}</strong> لتستطيع إنشاء مسابقة جديدة. يرجى التحقق من بريدك الإلكتروني والضغط على رابط التأكيد.",
  didntReceiveEmail: "لم تستلم رسالة التأكيد؟",
  resendVerificationEmail: "إعادة إرسال",
  verificationEmailSent: "تم إرسال رسالة التأكيد",
  emailAlreadySent: "يجب عليك الانتظار 15 دقيقة قبل إعادة إرسال رسالة التأكيد",
};
