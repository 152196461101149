export default {
  logout: "Logout",
  emailNotVerified: "Email not verified",
  emailNotVerifiedDescription:
    "You need to verify your email <strong>{{email}}</strong> to be able to create a new contest. Please check your email and click on the confirmation link.",
  didntReceiveEmail: "Didn't receive the email?",
  resendVerificationEmail: "Resend",
  verificationEmailSent: "Verification email sent",
  emailAlreadySent:
    "You need to wait 15 minutes before resending the verification email",
};
