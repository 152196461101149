export default {
  "results-overview": "نظرة عامة",
  "results-members": "الأعضاء",
  "contest-submissions": "المشاركات خلال أيام المسابقة",
  day: "يوم",
  "the-day": "اليوم",
  submissions: "المشاركات",
  members: "الأعضاء",
  "top-3": "أفضل 3",
  "no-of-submissions": "عدد المشاركات",
  today: "اليوم",
  of: "من",
  score: "النقاط",
  date: "التاريخ",
  selectMember: "اختر العضو",
  selectDay: "اختر اليوم",
  totalPoints: "إجمالي النقاط",
  average: "متوسط",
  rank: "الترتيب",
  pointsPerDay: "النقاط لكل يوم",
  scorePerCategory: "النقاط لكل فئة",
  viewSubmissionsPerDay: "عرض المشاركات لكل يوم",
  close: "إغلاق",
  save: "حفظ",
  dailySubmissionsPopup: {
    title: "المشاركات لكل يوم",
    load: "عرض",
    date: "التاريخ",
    noData: "لا يوجد بيانات",
    criteriaTitle: "المعيار",
    answer: "الإجابة",
    noSubmissions: "لا يوجد مشاركات لهذا اليوم",
    selectDate: "اختر التاريخ",
    points: "النقاط",
    lastUpdated: "آخر تحديث",
  },
  "no-results": "لا يوجد نتائج",
  failedToSave: "فشل في الحفظ",
  back: "رجوع",
  showResults: "عرض النتائج",
};
