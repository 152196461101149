export default {
  add: "Add",
  "double-click-to-edit": "Double click to edit",
  "must-select-contest": "You must select a contest first",
  "contest-has-been-edited-successfully": "Contest edited successfully",
  "contest-isn't-edited-successfully": "Failed to edit contest",
  "no-announcements-yet": "No announcements yet",
  "contest-announcements": "Contest announcements",
  "contest-information": "Contest information",
  "save-changes": "Save Changes",
  "make-an-announcement": "Make new announcement",
  "new-announcement": "New announcement",
  announcement: "Announcement",
  announcements: "Announcements",
  "announcement-placeholder": "Enter announcement content here",
  "success-to-add-new-announcement-msg": "New announcement added successfully",
  "fail-to-add-new-announcement-msg": "Failed to add new announcement",
  "active-announcements": "Contest Announcements",
  save: "Save",
  readonly: "Read Only",
  "days-to-record-in-past": "Allow recording for",
  "days-to-record-in-past-msg":
    "How many days a student can record points after that day",
  "no-contest-yet-msg": "You don't have any active contests",
  "no-contest-yet": "No contests",
  "delete-this-announcement": "Delete this announcement",
  "name-label": "Name",
  "code-label": "Code",
  "description-label": "Description",
  update: "Update",
  reset: "Reset",
  "join-code": "Join Code",
  "copy-link": "Copy Invite Link",
  "switch-contest": "Switch Contest",
  "add-new-announcement": "New Announcement",
  "announcement-content": "Announcement Content",
  ads: "Advertising",
  adsDisclimar: "The advertisement must not contain;",
  editDisclimar: "Edit ads",
  notAcceptAds: "Advertisements have not been modified",
  acceptAds: "Ads have been successfully modified",
  contestKey: "You must choose a contest",
  moderatorsKey: "Moderators",
  seeAll: "See all",
  groupsKey: "Groups",
  participantsKey: "Participants",
  accessCode: "Access Code:",
  selectKey: "Select",
  "contest-code": "Contest Code",
  "contest-name": "Contest Name",
  "contest-description": "Contest Description",
  "start-date": "Start Date",
  "end-date": "End Date",
  "contest-name-required-error": "Contest name is required",
  "start-date-required-error": "Start date is required",
  "end-date-required-error": "End date is required",
  "end-date-invalid-error": "End date is invalid",
  "contest-code-required-error": "Contest code is required",
  "contest-code-invalid-error": "Contest code is invalid",
  contestStatus: {
    not_started: "Not Started",
    started: "Ongoing",
    finished: "Finished",
  },
  contestNotStarted: "Contest not started yet",
  noTopStudentsYet: "No top students yet",
  "show-leaderboard-for-students": "Show Leaderboard for Students",
  country: "Country",
  "code-error": "Invalid code",
  days: "Days",
};
