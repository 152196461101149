export default {
  reviewText: "Review text entries",
  selectStudent: "Select the student",
  chooseDay: "Choose today",
  selectStudentDay: "Select the student and day",
  studentText: "There are no student text entries on this day",
  standardKey: "Choose the standard",
  textEntered: "Text entered by the student",
  notTextEntered: "The student did not make any entry",
  addressKey: "the address",
  limitReptition: "The upper limit of repetition",
  enterResult: "Enter the result",
  addResult: "Add the result",
  notStudent: "There are no students",
  notAddResult: "The result has not been added",
  succussResult: "The result has been added successfully",
  mustEnter: "You must enter a result to add it",
  createKey: "Create",
  joinKey: "Join",
  newContest: "New Contest Name",
};
