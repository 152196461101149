export default {
  login: "تسجيل الدخول",
  signUp: "انشاء حساب",
  alreadyHaveAccount: "    هل لديك حساب؟",
  loginNow: " !تسجيل الدخول الآن ",
  userNameKey: "اسم المستخدم",
  userNameDisclimar:
    "يمكن أن يحتوي اسم المستخدم على حروف وأرقام و@/./+/-/_ فقط.",
  passwordKey: "كلمة المرور",
  retypePasswordDisclimar: "كلمات المرور هذه غير متطابقة. حاول ثانية.",
  retypePassword: "أعد إدخال كلمة المرور",
  lastName: "الاسم الأخير",
  firstName: "الاسم الأول",
  emailAddress: "عنوان البريد الإلكتروني",
  phoneNumber: "رقم الهاتف",
  profilePhoto: " الصورة الشخصية",
  familyName: "اسم العائلة",
  emailAddressKey: "البريد الإلكتروني",
  passwordValidation: "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
  confirmPassword: "تأكيد كلمة المرور",
  matchPassword: "لإدخال غير صحيح، تأكد من مطابقة كلمة المرور",
  reassign: "إعادة نعيين",
  reassignMSG: "تم إعادة تعيين كلمة المرور بنجاح",
  notReassignMSG: "لم يتم  إعادة تعيين كلمة المرور",
  forgetPassOrUsername: "نسيت كلمة المرور أو اسم المستخدم؟",
  checkEmail: "تحقق من بريدك الالكتروني.",
  resetPass: "إعادة تعيين كلمة المرور",
  backLogin: "العودة إلى تسجيل الدخول",
  orKey: "أو",
  notAccount: "ليس لديك حساب؟",
  signUpKey: "اشتراك",
  checkPassword: "تحقق من بريدك الإلكتروني وكلمة المرور أو قم بإنشاء حساب.",
  characterKey: "استخدم 8 أحرف أو أكثر.",
  passwordMatch: "كلمات المرور هذه غير متطابقة. حاول ثانية.",
  resetPassWord: "إعادة تعيين كلمة المرور الآن",
  wirdSupport: "دعم وِرد",
  requiredField: "هذا الحقل مطلوب",
  copyrightFooterMsg: "\u00A9 جميع الحقوق محفوظة لتطبيق ورد - {{year}}",
};
