export default {
  "add-moderator-manually": "إضافة مسؤول يدويًا",
  go: "أضف",
  deactivate: "إلغاء تنشيط",
  moderators: "المسؤولون",
  search: "ابحث",
  "add-admin": "إضافة",
  "add-as-admin": "إضافته كمسؤول",
  "add-as-super-admin": "إضافته كمسؤول رئيسي",
  notification: {
    success: "تم تعديل المشرف بنجاح",
    error: "حدث خطأ ما، يرجى إعادة المحاولة لاحقا",
    addModerator: "تمت الإضافة بنجاح",
    errorModerator: "يرجى التحقق من الاسم",
  },
};
