export default {
  username: "اسم المستخدم",
  delete: "حذف",
  "create-contest": "إنشاء مسابقة",
  "join-contest": "انضم إلى مسابقة",
  notAdmin: "يجب أن تكون مشرفاً على الأقل في المسابقة للوصول إلى هذه الصفحة",
  notSuperAdmin:
    "يجب أن تكون مشرفاً رئيسياً على الأقل في المسابقة لعرض هذا المحتوى",
  forbidden: "ليس لديك صلاحية الوصول",
  "are-you-sure": "هل أنت متأكد؟",
  "saved-successfully": "تم الحفظ بنجاح",
  showing: "يتم عرض",
  "danger-zone": "منطقة الخطر",
  "deleting-contest-description":
    "سيؤدي حذف المسابقة إلى حذف جميع البيانات المتعلقة بها بشكل دائم",
  users: "المستخدمون",
  groups: "المجموعات",
  points: "النقاط",
  results: "النتائج",
  "action-cannot-be-undone": "هذا الإجراء لا يمكن التراجع عنه",
  "delete-contest": "حذف المسابقة",
  "write-contest-code-to-confirm": "اكتب كود المسابقة لتأكيد الحذف",
  "write-contest-code": "اكتب {{code}} لتأكيد الحذف",
  "contest-removed": "تم حذف المسابقة",
};
