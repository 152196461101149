export default {
  "results-overview": "Overview",
  "results-members": "Members",
  "contest-submissions": "Submissions throughout the contest",
  day: "Day",
  "the-day": "Day",
  submissions: "Submissions",
  members: "Members",
  "top-3": "Top 3",
  "no-of-submissions": "No. of submissions",
  today: "Today",
  of: "of",
  score: "Score",
  date: "Date",
  selectMember: "Select member",
  selectDay: "Select day",
  totalPoints: "Total points",
  average: "Average",
  rank: "Rank",
  pointsPerDay: "Points per day",
  scorePerCategory: "Score per category",
  viewSubmissionsPerDay: "View submissions per day",
  close: "Close",
  save: "Save",
  dailySubmissionsPopup: {
    title: "Submissions per day",
    load: "Load",
    date: "Date",
    noData: "No data",
    criteriaTitle: "Criteria",
    answer: "Answer",
    noSubmissions: "No submissions for this day",
    selectDate: "Select date",
    points: "Points",
    lastUpdated: "Last updated",
  },
  "no-results": "No results",
  failedToSave: "Failed to save",
  back: "Back",
  showResults: "Show results",
};
