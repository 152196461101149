export default {
  "home-page": "Creator Dashboard",
  "contest-information": "Contest Information",
  "leaders-board": "Leaderboard",
  admins: "Admins",
  students: "Participants",
  criterias: "Contest Criteria",
  "text-inputs": "Text Inputs",
  "results-page": "Results Page",
  "extract-results": "Extract Results",
  "groups-page": "Groups",
  "top-3-rank": "Top 3 rank",
  "see-all": "See all",
  "see-contest-result": "See Contest Result",
  "today-is": "Today Is",
  ramadan: "Ramadan",
  welcome: "Welcome",
  logout: "Logout",
  help: "Help",
  "my-account": "My Account",
  language: "العربية",
  ourName: "Wird platform",
  welcomeMsg:
    "Welcome to the competition official website on the Ward platform. In this place you can follow the details of the contestants. For more details, please watch the user guide through the video attached below.",
  ongingContst: "My Ongoing Contest",
  pastContest: "My Past Contests",
  participantsKey: "Participants",
  loading: "Loading",
};
