export default {
  "home-page": "الصفحة الرئيسية",
  "contest-information": "معلومات المسابقة",
  "leaders-board": "الأوائل",
  admins: "المسؤولون",
  participants: "المشاركون",
  criterias: "المعايير",
  "text-inputs": "مراجعة المدخلات النصية",
  "results-page": "مشاهدة النتائج",
  "extract-results": "استخراج النتائج",
  "groups-page": "المجموعات",
  "top-3-rank": " الثلاثة الأوائل",
  "see-all": "المزيد",
  "see-contest-result": "رؤية نتائج المسابقة",
  "today-is": "اليوم هو ",
  ramadan: "من شهر رمضان",
  welcome: "أهلًا",
  logout: "تسجيل الخروج",
  help: "مساعدة",
  "my-account": "حسابي",
  language: "English",
  ourName: "منصة ورد ",
  welcomeMsg:
    "أهلا بك في موقع مسؤول المسابقة في منصة ورد، في هذا المكان يمكنك متابعة تفاصيل المتسابقين، ولمزيد من التفاصيل يرجى مشاهدة دليل الاستخدام من خلال الفيديو المرفق بالاسفل ",
  ongingContst: "مسابقتي المستمرة",
  pastContest: "مسابقاتي السابقة",
  participantsKey: "مشاركون",
  loading: "جاري التحميل",
};
