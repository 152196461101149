export default {
  "add-group": "Add Group",
  groups: "Groups",
  "delete-confirm": "Confirm deletion",
  "delete-group-confirm-description": "Do you want to delete this group?",
  cancel: "Cancel",
  joined: "Joined on",
  participants: "Participants",
  edit: "Edit",
  delete: "Delete",
  quit: "Quit",
  "delete-group-failed-msg":
    "Please remove or move students to another group before deleting this group",
  "no-groups": "You Don't have Groups Yet",
  // add group Form
  "should-select-moderator": "You should Select Moderator to this Group",
  "add-group-success": "The Group was Added Successfully",
  "group-not-add": "No group has been added",
  "suggestion-students": "Select group's students",
  "no-students": "There are no students",
  "select-moderator": "Select the Moderator",
  "enter-group-name": "Enter the New Group Name",
  "group-name": "Group Name",
  "group-name-condition":
    "The Group Name must be less than or equal 30 charachters",
  adve: "Advertisement",
  "adve-condition": "The Add Should not contain at ;",
  add: "Add Group",
  "add-cancel": "Cancel Add Group",
  // edit group Form
  "group-condition": "You should Select the Group",
  "group-edit-success": "The group has been modified successfully",
  "group-edit-failed": "The group has not been modified",
  "selected-group": "The group to be modified",
  "enter-new-group-name": "Enter the New Group Name",
  "edit-btu": "Edit Group",
  "edit-cancel": "Cancel",
  "select-group": "Select Group",
  name: "Name",
  "select-member": "Select Member",
  "add-member": "Add Member",
  "group-updated": "Group updated successfully",
  "create-group": "Create Group",
  "no-groups-found": "No groups created yet.",
  "groups-description":
    "Groups allow you to divide contest members into different groups and assign an admin to each group. You can add students to groups and assign admins to them. You can also add announcements to each group.",
  create: "Create",
  "delete-group-confirm": "Are you sure you want to delete this group?",
  "group-info": "Group Information",
  "select-role": "Select Role",
  "group-roles": {
    member: "Member",
    admin: "Admin",
  },
  "group-member-updated": "Member updated successfully",
  "change-role-to-member": "Change Role to Member",
  "change-role-to-admin": "Change Role to Admin",
  remove: "Remove",
  select: "Select",
  "add-admins": "Add Admins",
  "add-members": "Add Members",
};
