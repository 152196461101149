export default {
  save: "حفظ",
  "profile-photo": "صورة شخصية",
  "phone-number": "رقم الهاتف",
  "new-email-address": "عنوان البريد الالكتروني الجديد",
  "last-name": "الاسم الأخير",
  "first-name": "الاسم الأول",
  "re-type-new-password": "كلمة المرور مرة أخرى",
  "new-password": "كلمة المرور",
  tryAgainPassword: "كلمات المرور هذه غير متطابقة. حاول ثانية.",
  extractResult: "استخراج النتائج",
  fromDay: "من يوم",
  untilDay: " إلى يوم",
  yourResult: "يمكنك استخراج النتائج مرة واحدة يوميا",
  extractKey: "استخراج",
  noResult: "لم يتم استخراج النتائج",
  succussResult: "تم استخراج النتائج بنجاح",
  dayResult: "اختر الآبام لاستخراج النتائج",
  "edit-profile": "تعديل الملف الشخصي",
  "change-profile-photo": "تغيير صورة الملف الشخصي",
  "profile-has-been-edited-successfully": "تم تعديل الملف الشخصي بنجاح",
  "something-went-wrong": "حدث خطأ ما",
};
