export default {
  "enter-title": "ادخل العنوان",
  "criteria-order": "ترتيب المعيار داخل القسم",
  "choose-type": "اختر نوع النموذج",
  digital: "رقمي",
  "true-false": "خانة اختيار - صح أو خطأ",
  "text-needs-revision": "نصي - يحتاج مراجعة مسؤول",
  "points-description": "وصف النقاط - مثال : نقطتان لكل صفحة",
  "enter-repetition": "ادخل عدد نقاط لكل تكرار",
  limited: "متاح لأيام محددة",
  "show-criteria": "عرض المعيار",
  "activate-criteria": "تفعيل المعيار",
  "add-criteria": "إضافة معيار",
  "edit-criteria": "تعديل معيار",
  "minimum-repeat": "الحد الأدنى للتكرار",
  "maximum-repeat": "الحد الأعلى للتكرار",
  "section-order": "ترتيب القسم داخل المسابقة",
  "should-add-section-name": "يجب إضافة اسم القسم",
  "success-add-section-msg": "تم إضافة القسم بنجاح",
  "success-edit-section-msg": "تم تعديل القسم بنجاح",
  "fail-add-section-msg": "لم يتم إضافة القسم",
  "fail-edit-section-msg": "لم يتم تعديل القسم",
  "add-section": "إضافة قسم جديد",
  "edit-section": "تعديل القسم",
  edit: "تعديل",
  delete: "حذف",
  cancel: "إلغاء",
  sections: "الأقسام",
  // criterias: "المعايير",
  "ramadan-word": "رمضان",
  "choose-section": "اختر القسم",
  "no-sections": "لا يوجد أقسام - يجب إضافة قسم",
  "select-specific-days": "اختر الأيام",
  "success-add-standard-msg": "تم إضافة المعييار بنجاح",
  "success-edit-standard-msg": "تم تعديل المعييار بنجاح",
  "fail-add-standard-msg": "لم يتم إضافة المعييار",
  "fail-edit-standard-msg": "لم يتم تعديل المعييار",
  criteriaCheck: "هل أنت متأكد أنك تريد حذف هذا القسم؟",
  criteriaQuestion: "هل أنت متأكد أنك تريد حذف هذا المعيار؟",
  notCriteriaAdd: "لم يتم إضافة المعيار",
  criteriaAdd: "تم إضافة المعيار بنجاح",
  displaySection: "لا يوجد أقسام لعرضها",
  checkBoxChecker: "خانة إختيار - صح أو خطأ",
  textReview: "نصي - يحتاج مراجعة من المسؤول",
  enterCreterion: "ادخل النقاط لهذا المعييار",
  chooseDay: "اختر الايام ليكون متاحا",
  criteriaDisplay: "لا يوجد معايير لعرضها",
  mustEdit: "يجب عليك إختيار قسم لتعديله",
  modifySuccess: "تم تعديل المعيار بنجاح",
  notModifySuccess: "لم يتم تعديل المعيار",
  deleteRecord: "يرجى حذف جميع المعايير المسجلة في هذا القسم قبل حذفه",
  deleteAllStudents: "يرجى حذف جميع نقاط الطلاب المسجلة لهذا المعييار قبل حذفه",
  addSection: "إضافة قسم",
  currentSections: "الأقسام الحالية",
  currentStandards: "المعايير الحالية",
  deleteSection: "هل تريد حذف هذا القسم؟",
  deleteCriterion: "هل تريد حذف هذا المعييار؟",
  noStandard: "لا يوجد معايير",
  chooseStandard: "يجب عليك اختيار معيار لتعديله",
  preview: "معاينة",
  "no-criterias-added": "لا يوجد معايير مضافة",
  "section-name": "اسم القسم",
  fieldType: {
    text: "نص",
    number: "رقم",
    checkbox: "اختيار",
    multipleChoices: "اختيار متعدد",
    radio: "اختيار من متعدد",
  },
  "criteria-basic": "عام",
  "criteria-advanced": "متقدم",
  "criteria-type": "نوع المعيار",
  "criteria-title": "الاسم",
  "criteria-description": "الوصف",
  "criteria-points": "النقاط",
  "criteria-show-on-dates": "إظهار في التواريخ",
  "criteria-hide-on-dates": "إخفاء في التواريخ",
  "allow-multiline": "السماح بأكثر من سطر",
  "criteria-min": "الحد الأدنى",
  "criteria-max": "الحد الأعلى",
  "checked-label": "النص عند الاختيار",
  "unchecked-label": "النص عند عدم الاختيار",
  options: "الخيارات",
  "add-option": "إضافة خيار",
  "section-updated": "تم تعديل القسم بنجاح",
  "section-deleted": "تم حذف القسم بنجاح",
  "section-added": "تم إضافة القسم بنجاح",
  yes: "نعم",
  no: "لا",
  points_one: "نقطة واحدة",
  points_two: "نقطتان",
  points_few: "{{count}} نقاط",
  points_other: "{{count}} نقطة",
  "update-criteria": "تعديل المعيار",
  "criteria-visible": "إظهار المعيار",
  "criteria-active": "تفعيل المعيار",
  "criteria-deleted": "تم حذف المعيار بنجاح",
  "criteria-delete-failed": "فشل حذف المعيار",
  "criteria-updated": "تم تعديل المعيار بنجاح",
  "criteria-added": "تم إضافة المعيار بنجاح",
  "criteria-operation-failed": "حدث خطأ أثناء تنفيذ العملية",
  "cannot-edit-contest-after-start": "لا يمكن تعديل المسابقة بعد بدئها",
  "delete-section-confirm": "هل تريد حذف هذا القسم؟",
  "delete-section-confirm-description":
    "سيتم حذف جميع المعايير المسجلة في هذا القسم",
  "section-delete-failed": "فشل حذف القسم",
  "criteria-type-change-warning": "لا يمكن تغيير نوع المعيار بعد إنشائه",
  "partial-points": "النقاط جزئية",
  "is-correct": "إجابة صحيحة",
  option: "خيار",
  "contest-started-warning": "لا يمكن حذف الأقسام والمعايير بعد بدء المسابقة",
  "correct-option-required": "يجب اختيار إجابة صحيحة",
};
