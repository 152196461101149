export default {
  login: "Login",
  signUp: "Sign up",
  alreadyHaveAccount: "Already have an account?",
  loginNow: "Login now!",
  userNameKey: "Username",
  userNameDisclimar: "Username can contain Letters, digits and @/./+/-/_ only.",
  passwordKey: "Password",
  retypePasswordDisclimar: "These passwords didn't match. Try again.",
  retypePassword: "Re-type password",
  lastName: "Last Name",
  firstName: "First Name",
  emailAddress: "Email address",
  phoneNumber: "Phone Number",
  profilePhoto: " Profile photo",
  familyName: "Family Name",
  emailAddressKey: "Email",
  passwordValidation: "The password must be at least 8 characters long",
  confirmPassword: "confirm password",
  matchPassword: "For an incorrect entry, make sure the password matches",
  reassign: "Reappointment",
  reassignMSG: "The password has been reset successfully",
  notReassignMSG: "Password not reset",
  modifyAdminChanged: "You must choose an administrator to reset your password",
  forgetPassOrUsername: "Forgot your password or username?",
  checkEmail: "Check your email.",
  resetPass: "Reset Password",
  backLogin: "back to Login",
  orKey: "Or",
  notAccount: "Don’t have an account?",
  signUpKey: "Sign up",
  checkPassword: "Check your email and password or create an account.",
  characterKey: "Use 8 or more characters.",
  passwordMatch: "Those passwords didn't match. Try again.",
  resetPassWord: "Reset Password Now",
  wirdSupport: "Wird Support",
  reviewText: "Review text entries",
  requiredField: "This field is required",
  copyrightFooterMsg: "\u00A9 All rights reserved Wird.app - {{year}}",
};
