export default {
  save: "Save",
  "profile-photo": "Profile Photo",
  "phone-number": "Phone Number",
  "new-email-address": "New Email Address",
  "last-name": "Last Name",
  "first-name": "First Name",
  "re-type-new-password": "Re-type New Password",
  "new-password": "New Password",
  tryAgainPassword: " These passwords didn't match. Try again.",
  extractResult: "Extract results",
  fromDay: "from day",
  untilDay: "until today",
  yourResult: "You can extract the results once daily",
  extractKey: "Extract",
  noResult: "Results were not extracted",
  succussResult: "The results were extracted successfully",
  dayResult: "Select the name to extract the results",
  "edit-profile": "Edit Profile",
  "change-profile-photo": "Change Profile Photo",
  "profile-has-been-edited-successfully":
    "Profile has been edited successfully",
  "something-went-wrong": "Something went wrong",
};
