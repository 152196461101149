import {colors} from "./index";

/** @type {import("antd").ThemeConfig}*/
export const lightTheme = {
  token: {
    colorPrimary: colors.orange,
    fontFamily:
      '"IBM Plex Sans Arabic", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif',
    controlHeight: 40,
    colorLink: colors.purple,
  },
};
