export default {
  groups: "المجموعات",
  "add-group": "إضافة مجموعة",
  "delete-confirm": "تأكيد الحذف",
  "delete-group-confirm-description": "هل تريد حذف هذه المجموعة ؟",
  cancel: "إلغاء",
  joined: "تم الإنضمام",
  participants: "المشاركون",
  edit: "تعديل",
  delete: "حذف",
  quit: "مغادرة",
  "delete-group-failed-msg":
    "الرجاء حذف الطلاب من هذه المجموعة أو نقلهم لمجموعة أخرى قبل الحذف",
  "no-groups": "لا يوجد لديك مجموعات حتى الآن",
  // add group Form
  "should-select-moderator": "يجب إضافة مسؤول لهذه المجموعة",
  "add-group-success": "تم إضافة المجموعة بنجاح",
  "group-not-add": "لم يتم إضافة أي مجموعة",
  "suggestion-students": "طلاب يمكنك إضافتهم",
  "no-students": "لا يوجد طلاب",
  "select-moderator": "اختر المسؤول",
  "enter-group-name": "أدخل إسم المجموعة",
  "group-name": "إسم المجموعة",
  "group-name-condition": "إسم المجموعة يجب أن يقل أو يساوي 30 حرف",
  adve: "الإعلان",
  "adve-condition": "الإعلان يجب أن لا يحتوي على :",
  add: "إضافة مجموعة",
  "add-cancel": "إلغاء إضافة المجموعة",
  // edit group Form
  "group-condition": "يجب اختيار مجموعة",
  "group-edit-success": "تم تعديل المجموعة بنجاح",
  "group-edit-failed": "لم يتم تعديل المجموعة",
  "selected-group": "المجموعة المراد تعديلها هي",
  "enter-new-group-name": "أدخل اسم المجموعة الجديد",
  "edit-btu": "تعديل المجموعة",
  "edit-cancel": "إلغاء",
  "select-group": "اختر المجموعة",
  name: "الاسم",
  "select-member": "اختر العضو",
  "add-member": "إضافة عضو",
  "group-updated": "تم تعديل المجموعة بنجاح",
  "create-group": "إنشاء مجموعة",
  "no-groups-found": "لا يوجد مجموعات حتى الآن.",
  "groups-description":
    "تمكنك المجموعات من تقسيم أعضاء المسابقة إلى مجموعات مختلفة وتعيين مسؤول لكل مجموعة. يمكنك إضافة الطلاب إلى المجموعات وتعيين مسؤولين لهم. يمكنك أيضًا إضافة إعلانات لكل مجموعة.",
  create: "إنشاء",
  "delete-group-confirm": "هل أنت متأكد من أنك تريد حذف هذه المجموعة؟",
  "group-info": "معلومات المجموعة",
  "select-role": "اختر الدور",
  "group-roles": {
    member: "عضو",
    admin: "مسؤول",
  },
  "group-member-updated": "تم تعديل العضو بنجاح",
  "change-role-to-member": "تغيير الدور إلى عضو",
  "change-role-to-admin": "تغيير الدور إلى مسؤول",
  remove: "إزالة",
  select: "اختر",
  "add-members": "إضافة أعضاء",
  "add-admins": "إضافة مسؤولين",
};
