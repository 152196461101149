export default {
  add: "اضف",
  "double-click-to-edit": "أنقر مرتين للتعديل",
  "must-select-contest": "يجب عليك اختيار مسابقة",
  "contest-has-been-edited-successfully": "تم تعديل المسابقة بنجاح",
  "contest-isn't-edited-successfully": "لم يتم تعديل المسابقة",
  "no-announcements-yet": "لا يوجد إعلانات",
  "contest-announcements": "إعلانات المسابقة",
  "contest-information": "معلومات المسابقة",
  "save-changes": "حفظ التغييرات",
  "make-an-announcement": "أنشر إعلانًا جديدًا",
  "new-announcement": "إعلان جديد",
  announcement: "إعلان",
  announcements: "إعلانات",
  "announcement-placeholder": "أدخل محتوى الإعلان هنا",
  "success-to-add-new-announcement-msg": "تم إضافة إعلان جديد بنجاح",
  "fail-to-add-new-announcement-msg": " لم يتم إضافة إعلان جديد",
  "active-announcements": "إعلانات المسابقة",
  save: "حفظ",
  readonly: "قراءة فقط",
  "days-to-record-in-past": "تسجيل نقاط لغاية",
  "days-to-record-in-past-msg":
    "لكم يوم يستطيع الطالب تسجيل نقاط بعد انتهاء ذلك اليوم",
  "no-contest-yet-msg": "ليس لديك أي مسابقات نشطة",
  "no-contest-yet": "ليس لديك مسابقات",
  "delete-this-announcement": "حذف هذا الإعلان",
  "name-label": "الاسم",
  "code-label": "الكود",
  "description-label": "الوصف",
  update: "تعديل",
  reset: "إعادة تعيين",
  "join-code": "رمز الانضمام",
  "copy-link": "نسخ رابط الدعوة",
  "switch-contest": "تبديل المسابقة",
  "add-new-announcement": "إعلان جديد",
  "announcement-content": "محتوى الإعلان",
  ads: "الإعلان",
  adsDisclimar: "الإعلان يجب أن لا يحتوي على ;",
  editDisclimar: "تعديل الإعلانات",
  notAcceptAds: "لم يتم تعديل الإعلانات",
  acceptAds: "تم تعديل الإعلانات بنجاح",
  contestKey: "يجب عليك إختيار مسابقة",
  moderatorsKey: "المشرفون",
  seeAll: "اظهار الكل",
  groupsKey: "مجموعات",
  participantsKey: "مشاركون",
  accessCode: "رمز الدخول:",
  selectKey: "يختار",
  "contest-code": "كود المسابقة",
  "contest-name": "اسم المسابقة",
  "contest-description": "وصف المسابقة",
  "start-date": "تاريخ البدء",
  "end-date": "تاريخ الانتهاء",
  "contest-name-required-error": "اسم المسابقة مطلوب",
  "start-date-required-error": "تاريخ البدء مطلوب",
  "end-date-required-error": "تاريخ الانتهاء مطلوب",
  "end-date-invalid-error": "تاريخ الانتهاء غير صالح",
  "contest-code-required-error": "كود المسابقة مطلوب",
  "contest-code-invalid-error": "كود المسابقة غير صالح",
  contestStatus: {
    not_started: "لم تبدأ",
    started: "جارية",
    finished: "انتهت",
  },
  contestNotStarted: "لم تبدأ المسابقة بعد",
  noTopStudentsYet: "لا يوجد أعلى طلاب حتى الآن",
  "show-leaderboard-for-students": "عرض لوحة الأوائل للطلاب",
  country: "الدولة",
  "code-error": "كود غير صالح",
  days: "أيام",
};
