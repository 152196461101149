export default {
  addAdmin: "إضافته كمسؤول رئيسي",
  addNewAdmin: "إضافة مسؤول جديد",
  notAddAdminMSG: "لم تمت إضافة المسؤول",
  addAdminSuccess: "تمت إضافة المسؤول بنجاح",
  chooseAdmin: "اختر المسؤول",
  modifyAdmin: "تعديل المسؤول",
  notModifyAdminMSG: "لم يتم تعديل المسؤول",
  modifyAdminMSG: "تم تعديل المسؤول",
  modifyAdminChanged: "يجب عليك إختار مسؤول إعادة تعيين كلمة المرور'",
  deleteAdminDisclimar: "هل تريد حذف هذا المسؤول؟",
  deleteBtn: "حذف",
  cancelBtn: "إلغاء",
  adminsLabel: "المسؤولون",
  notAdmins: "لا يوجد مسؤولون",
};
