export default {
  "enter-title": "Enter Title ",
  "criteria-order": "Criteria order in section",
  "choose-type": "Choose type",
  digital: "Digital",
  "true-false": "True or False",
  "text-needs-revision": "Text - Needs revision",
  "points-description": "Description - e.g: 2 points for each page",
  "enter-repetition": "Enter the number of points for each repetition",
  limited: "Available for specific days",
  "show-criteria": "Show Criteria",
  "activate-criteria": "Activate criteria",
  "add-criteria": "Add Criteria",
  "edit-criteria": "Edit Criteria",
  "minimum-repeat": "Minimum repeat",
  "maximum-repeat": "Maximum repeat",
  "section-order": "Section order",
  "should-add-section-name": "Section label should be added",
  "success-add-section-msg": "The section has been added successfully",
  "success-edit-section-msg": "The section has been edited successfully",
  "fail-add-section-msg": "The section was not added",
  "fail-edit-section-msg": "The section was not edited",
  "add-section": "Add section",
  "edit-section": "Edit section",
  edit: "Edit",
  delete: "Delete",
  cancel: "Cancel",
  sections: "Sections",
  "ramadan-word": "Ramadan",
  "choose-section": "Choose section",
  "no-sections": "There are no sections - section should be added",
  "select-specific-days": "Select the days",
  "success-add-standard-msg": "The criteria has been added successfully",
  "success-edit-standard-msg": "The criteria has been edited successfully",
  "fail-add-standard-msg": "The criteria was not added",
  "fail-edit-standard-msg": "The criteria was not edited",
  criteriaCheck: "Are you sure you want to delete this section?",
  criteriaQuestion: "Are you sure you want to delete this criteria?",
  notCriteriaAdd: "The standard has not been added",
  criteriaAdd: "The criterion has been added successfully",
  displaySection: "There are no sections to display",
  checkBoxChecker: "Check box - true or false",
  textReview: "Text - needs review by administrator",
  enterCreterion: "Enter points for this criterion",
  chooseDay: "Choose the days to be available",
  criteriaDisplay: "There are no criteria to display",
  mustEdit: "You must select a section to edit",
  modifySuccess: "The standard has been successfully modified",
  notModifySuccess: "The standard has not been modified",
  deleteRecord:
    "Please delete all criteria recorded in this section before deleting it",
  deleteAllStudents:
    "Please delete all student points registered for this standard before deleting it",
  addSection: "Add section",
  currentSection: "Current sections",
  currentStandards: "Current standards",
  deleteSection: "Do you want to delete this section?",
  deleteCriterion: "Do you want to delete this criterion?",
  noStandard: "There are no standards",
  chooseStandard: "You must choose a criterion to modify",
  preview: "Preview",
  "no-criterias-added": "No criteria added",
  "section-name": "Section name",
  fieldType: {
    text: "Text",
    number: "Number",
    checkbox: "Toggle",
    multipleChoices: "Multiple choices",
    radio: "Radio",
  },
  "criteria-basic": "Basic",
  "criteria-advanced": "Advanced",
  "criteria-type": "Criteria type",
  "criteria-title": "Title",
  "criteria-description": "Description",
  "criteria-points": "Points",
  "criteria-show-on-dates": "Show on dates",
  "criteria-hide-on-dates": "Hide on dates",
  "allow-multiline": "Allow multiple lines",
  "criteria-min": "Minimum",
  "criteria-max": "Maximum",
  "checked-label": "Label for checked",
  "unchecked-label": "Label for unchecked",
  options: "Options",
  "add-option": "Add option",
  "update-criteria": "Update criteria",
  add: "Add",
  "section-updated": "Section updated",
  "section-added": "Section added",
  "section-deleted": "Section deleted",
  yes: "Yes",
  no: "No",
  points_one: "{{count}} Point",
  points_other: "{{count}} Points",
  "criteria-visible": "Visible",
  "criteria-active": "Active",
  "criteria-deleted": "Criteria deleted",
  "criteria-delete-failed": "Failed to delete criteria",
  "criteria-updated": "Criteria updated",
  "criteria-added": "Criteria added",
  "criteria-operation-failed": "Failed to perform the operation",
  "cannot-edit-contest-after-start": "Cannot edit the contest after it starts",
  "delete-section-confirm": "Do you want to delete this section?",
  "delete-section-confirm-description":
    "All criteria recorded in this section will be deleted",
  "section-delete-failed": "Failed to delete the section",
  "criteria-type-change-warning":
    "Criteria type cannot be changed after creation",
  "partial-points": "Partial points",
  "is-correct": "Is correct",
  option: "Option",
  "contest-started-warning":
    "Cannot delete sections and criteria after the contest starts",
  "correct-option-required": "At least one correct option is required",
};
