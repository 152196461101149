import {
  destroySession,
  isLogged,
  updateSessionUserDetails,
} from "../../services/auth/session";
import { redirect } from "react-router-dom";
import * as AuthApi from "../../services/auth/api";
import { ContestsApi } from "../../services/contests/api";
import { getCurrentContest } from "../../services/contests/utils";

export async function dashboardLoader({ request }) {
  const redirectTo = new URL(request.url).pathname;
  if (!isLogged()) {
    return redirect(`/login?redirectTo=${redirectTo}`);
  }
  const data = {};

  try {
    // make sure session still valid
    data.currentUser = await AuthApi.currentUserInfo();
    updateSessionUserDetails(data.currentUser);
  } catch (e) {
    destroySession();
    return redirect(`/login?redirectTo=${redirectTo}`);
  }

  try {
    data.contests = await ContestsApi.getContests();
    data.currentContest = await getCurrentContest(data.contests);
    data.currentUser.role = data.currentContest?.person_contest_role;
    data.currentContest.announcements = Array.isArray(
      data.currentContest.announcements,
    )
      ? data.currentContest.announcements
      : [];
  } catch (e) {
    console.log(`Failed to get current contest: ${e}`);
    data.currentContest = null;
  }

  return data;
}
