export default {
  addAdmin: "Add it as main administrator",
  addNewAdmin: "Add a new administrator",
  notAddAdminMSG: "Administrator not added",
  addAdminSuccess: "Administrator added successfully",
  chooseAdmin: "Select Administrator",
  modifyAdmin: "Edit Administrator ",
  notModifyAdminMSG: "The administrator has not been modified",
  modifyAdminMSG: "Administrator has been modified.",
  deleteAdminDisclimar: "Do you want to delete this admin?",
  deleteBtn: "delete",
  cancelBtn: "cancel",
  adminsLabel: "Officials",
  notAdmins: "There are no officials",
};
