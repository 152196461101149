export default {
  "accepted-participants": "المشاركون",
  addParticipantManually: "إضافة مشترك يدوياً",
  deactivatedStudents: "المشاركون غير المفعلين",
  rejectedParticipants: "المشاركون المرفوضون",
  show: "عرض",
  deactivate: "إلغاء التفعيل",
  "set-admin": "تعيين كمسؤول",
  "set-super-admin": "تعيين كمسؤول رئيسي",
  "change-password": "تغيير كلمة المرور",
  "enter-new-password": "أدخل كلمة مرور جديدة",
  "confirm-new-password": "تأكيد كلمة المرور الجديدة",
  reactive: "إعادة تفعيل",
  "unmatched-passwords": "كلمتا المرور ليستا متطابقتين",
  "reset-password-failed-msg": "لم يتم إعادة تعيين كلمة المرور",
  studentModify: "تعديل الطالب",
  photoUpdate: "تحميل صورة شخصية",
  addStudent: "إضافته كطالب يستطيع المشاهدة فقط",
  editStudent: "يجب عليك إختيار طالب للتعديل",
  successEdit: "تم تعديل الطالب بنجاح'",
  notSuccessEdit: "لم يتم تعديل الطالب",
  mustSelect: "يجب عليك اختيار متسابق لتغيير كلمة المرور",
  changePassword: "تم تغيير كلمة المرور بنجاح",
  notChangePassword: "لم يتم تغيير كلمة المرور'",
  enterPassword: "ادخل كلمة مرور جديدة",
  addNO: "ادخل رقم",
  saveSuccess: "تم حفظ التغيرات بنجاح",
  notSaveSuccess: "لم يتم حفظ التغيرات",
  noChange: "لم يحدث تغيير لحفظه",
  deleteSuccess: "تم الحذف بنجاح",
  notDelete: "لم يتم الحذف",
  selectStudentAndDay: "اختر اليوم والطالب",
  noPoints: "لا يوجد نقاط لهذا اليوم",
  deleteResult: "هل تريد حذف هذه النتيجة؟",
  standardTitle: "عنوان المعييار",
  result: "النتيجة",
  studentView: "اختر الطالب لعرض الإحصائيات لكل يوم",
  noStudentView: "لا يوجد نقاط لعرض الإحصائيات لكل يوم",
  statisticsDay: "الإحصائيات لكل يوم",
  statisticsEach: "اختر الطالب لعرض الإحصائيات لكل معييار",
  statisticsCriterion: "لا يوجد نقاط لعرض الإحصائيات لكل معييار",
  statisticsEachStandard: "الإحصائيات لكل معييار",
  noStudentDiplay: "لا يوجد طلاب لعرضهم",
  clickView: "اضغط لعرض الجدول",
  chooseContestant: "اختر المتسابق",
  viewChart: "اضغط لعرض الرسم البياني",
  chooseRamdan: "اختر اليوم من رمضان",
  mainRole: "وظيفة",
  role: {
    0: "مالك",
    1: "المشرف الرئيسي",
    2: "المشرف",
    3: "عضو",
    4: "عضو للقراءة فقط",
    5: "قيد الانتظار",
    6: "معطل",
  },
  notification: {
    success: "تم تعديل الطالب بنجاح",
    error: "حدث خطأ ما، يرجى إعادة المحاولة لاحقا",
    addStudent: "اضيف بنجاح",
    errorStudent: "يرجى التحقق من الاسم",
  },
  approve: "موافقة",
  reject: "رفض",
  "user-details": "تفاصيل المستخدم",
  email: "البريد الإلكتروني",
  all: "الكل",
  "add-user": "إضافة مستخدم",
  "change-role-to": "تغيير إلى",
  "show-results": "النتائج",
};
